<mat-nav-list class="px-4 sm:px-8" style="margin-top: 50px">
  <div *ngIf="!(user$|async)" class="flex justify-between mb-10 border border-gray w-40 text-lg p-2">
    <a (click)="openSigninDialog()" target="_blank" data-testid="button-sign-in"
      class="hover:text-primary cursor-pointer">Sign
      in</a>
    <div class="vl"></div>
    <a (click)="openSignupDialog()" data-testid="button-sign-up" class="hover:text-primary cursor-pointer">Sign
      up</a>
  </div>

  <div *ngIf="(user$|async)" class="flex flex-col space-y-1  mb-10">
    <div class='flex items-center cursor-pointer w-40 ' [routerLink]=" ['/@' + (user$|async)?.username]">

      <img class='w-12 h-12 max-w-12 max-h-12 object-cover rounded-full border-2 shadow'
        [src]="(user$|async)?.imageFile?.url? (user$|async)?.imageFile?.url:environment.mainUrl + '/assets/img/profile.png'"
        alt=''>
      <h1 class="title ltr:pl-4 rtl:pr-4 m-0 select-none ">{{(user$|async)?.firstName+' '+
        (user$|async)?.lastName}}
      </h1>
    </div>

    <div *ngIf="(user$|async)?.isEmailVerified && !(user$|async)?.needsPassword"
      class="flex justify-between text-primary">
      <a class="sm:text-lg self-center" [routerLink]='"/client-appointments"'>{{"_menu.content.my-appointments" |
        translate}}</a>
      <button mat-icon-button (click)="logout()" class="self-center"><mat-icon>logout</mat-icon>
      </button>
      <!-- <p class="self-center">|</p> -->

    </div>

    <p (click)="setPassword()" *ngIf="(user$|async)?.needsPassword" class="cursor-pointer text-red">{{
      '_menu-user.click-verify-password' | translate }}
    </p>

    <p (click)="verifyEmail()" *ngIf="!(user$|async)?.isEmailVerified" class=" cursor-pointer text-red">{{
      '_menu-user.click-verify-email' | translate }}
    </p>

  </div>


  <ul *ngIf="userProfile?.user" class="flex flex-col space-y-4" id="nav-public-user">
    <!--<li>
      <a class="underlined text-3xl" [routerLink]='"/@"+userProfile?.user?.username+"/o"'>{{"_general.offers" |
        translate}}</a>
    </li>-->
    <li class="nav-link">
      <a class="underlined text-3xl" [routerLink]='"/@"+userProfile?.user?.username'
        routerLinkActive="underlined-active-link" [routerLinkActiveOptions]="{ exact: true }">{{"_general.profile" |
        translate}}</a>
    </li>
    <li class="nav-link" *ngIf="userProfile?._productsCount">
      <a class="underlined text-3xl" [routerLink]='"/@"+userProfile?.user?.username+"/p"'
        routerLinkActive="underlined-active-link">{{"_general.services" |
        translate}}</a>
    </li>
    <li class="nav-link" *ngIf="userProfile?._storiesCount">
      <a class="underlined text-3xl" [routerLink]='"/@"+userProfile?.user?.username+"/stories"'
        routerLinkActive="underlined-active-link">{{"_stories.stories" |
        translate}}</a>
    </li>

  </ul>

  <!-- <ul class="mt-4 flex flex-col space-y-4" id="nav-client">
    <li>
  
    </li>

  </ul> -->
</mat-nav-list>